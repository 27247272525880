var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"226"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/n";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

import { HttpClient as HttpClientIntegration} from "@sentry/integrations";
import { beforeBreadcrumbHelper } from './lib/sentryutils';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;


let networkAllowRegex = new RegExp(/^(\/n\/api\/getjwttoken)|(https:\/\/elton\.rawumberstudios\.com\/now)|(https:\/\/rawumberstudios\.com\/x\/mycountrycode)|(\/n\/api\/registerbrowser)/);
let errorRegex = new RegExp(/requestFullscreen|exitFullscreen|AbortError: The play/);
let browserRegEx = new RegExp(/bingbot|bytespider/i);

if (process.env.NEXT_PUBLIC_NO_SENTRY!="1")
{
Sentry.init({
  dsn: SENTRY_DSN || 'https://c3f958fde5c34844bc1ce2ec6e911f6b@o4505025847885824.ingest.sentry.io/4505025847951360',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps

  
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.005,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  //ignoreErrors: [/requestFullscreen/, /jwplayer/],

  beforeSend(event, hint)
  {

    /*
    console.log("** IN BEFORESEND");
    console.log("Send");
    console.log(event);
    console.log("Hint");
    console.log(hint);*/

    let h = hint?.originalException;

    if (h instanceof Error)
    {   

      //console.log("** IN BEFORESEND FOR ERROR");


      let h2 = h as Error;  
      let e = h2.message;

      //("message: " + e);
      //console.log("stack: " + h2.stack);

      if (e?.match(errorRegex))
      {
        return null;
      }

      if (h2.stack?.includes("jwplayer") && h2.name=='IndexSizeError')
      {
        return null;
      }

      if (h2?.stack?.match(errorRegex))
      {
        return null;
      }

      if (event.request?.headers)
      {
        let userAgent=event.request?.headers["user-agent"];
        if (userAgent?.match(browserRegEx))
        {
          return null;
        }
      }
  

      //console.log(event.tags);
    
    }

    return event;

  },
  
  beforeBreadcrumb(breadcrumb : Sentry.Breadcrumb, hint? : Sentry.BreadcrumbHint)
  {
    return beforeBreadcrumbHelper(breadcrumb, hint);
  },


  integrations: [
    new Sentry.Replay(
      {  
        networkDetailAllowUrls: [networkAllowRegex],
        networkRequestHeaders: ["Cookie"],
        maskAllText:false,
        maskAllInputs:false,
        blockAllMedia:false

      }
    ),
    new HttpClientIntegration({
      // This array can contain tuples of `[begin, end]` (both inclusive),
      // single status codes, or a combination of both.
      // default: [[500, 599]]
  
      // This array can contain Regexes, strings, or a combination of both.
      // default: [/.*/]
  
    }),
   
  ],

  
});
}